<template>

<!-- 机构端 校园筛查 筛查前设置 账户管理 -->
  <div class="page">
    <div class="header">
      <div class="header-input">
      </div>
      <div class="header-search">
        <el-input
          placeholder="请输入姓名/手机号"
          size="mini"
          class="search"
          prefix-icon="el-icon-search"
          v-model="searchValue"
          maxlength="11"
        ></el-input>
      </div>
      <div class="header-button">
        <div class="header-button-action">
          <el-button type="primary" size="mini" round @click="search">搜索</el-button>
          <el-button type="primary" size="mini" round @click="reset">重置</el-button>
        </div>
        <div class="header-button-add">
          <el-button type="primary" size="mini" round icon="el-icon-plus" @click="goTo('/detection/accountAdd')">新增账户</el-button>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="table-main">
        <el-table :data="tablePageData" @selection-change="handleSelectionChange" :row-class-name="tableRowClassName" style="width: 100%;">
          <el-table-column type="selection" align="center">
          </el-table-column>
          <el-table-column prop="schoolName" label="学校" :formatter="formNull"  align="center">
          </el-table-column>
          <el-table-column prop="sysName" label="姓名" :formatter="formNull" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="手机" :formatter="formNull"  align="center">
          </el-table-column>
          <el-table-column prop="roleType" label="角色" :formatter="formatterRoleType"  align="center">
          </el-table-column>
          <el-table-column label="是否启用"  align="center">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isAbleS" @change="changeSwitch(scope.row.isAble==0?false:true,scope)" ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作"  align="center">
            <template slot-scope="scope">
              <!-- <el-button size="small" type="text" @click="goTo('/detection/accountEdit',scope.row)">修改</el-button> -->
              <el-button size="small" type="text" style="color: #FF0000;" slot="reference" @click="delSysUser(0,scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
          <div slot="empty">
            <div :style="{'marginTop': '52px'}">
              <img src="@/assets/detection/image/404.png" alt="" width="333" />
            </div>
          </div>
        </el-table>
        <div class="table-page">
          <el-button
            :type="batchNum?'primary':'disabled'"
            :disabled="batchNum?false:true"
            size="mini"
            class="delete-button" round
            @click="delSysUser(1)"
          >批量删除({{batchNum}})</el-button>
          <pagination :current-page="startPage" :total="totalPage"  @currentChange="handleCurrentChange" @sizeChange="sizeChange"></pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SysService from '@/globals/service/detection/Sys.js'

export default {

  data () {
    return {
      searchValue: '',
      totalPage: 0,
      total: 0,
      startPage: 1, // 当前页码
      pageSize: 8,
      tableData: [],
      tablePageData: [],
      multipleSelection: [],
      batchNum: 0,
      ruleForm: {
        phone: '',
        password: ''
      },
      searchStatue: false
    }
  },

  mounted () {
    this.getListData()
    this.isInfo()
  },
  computed: {
    getIsAble (value) {
      return value === 0
    }
  },
  activated () {
  },
  methods: {
    sizeChange (val) {
      this.pageSize = val
      this.getListData()
    },
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    changeSwitch (type, obj) {
      const id = obj.row.id
      let msg = ''
      if (type) {
        type = 0
        msg = '已启用'
      } else {
        type = 1
        msg = '已禁用'
      }
      SysService.type({ type: type, ableId: id }).then(res => {
        this.tablePageData[obj.$index].isAble = type
        this.tablePageData[obj.$index].isAbleS = type === 0
        this.$set(this.tablePageData, obj.$index, this.tablePageData[obj.$index])
        this.$message.success(msg)
      })
    },
    getListData (startPage) {
      SysService.list({ startPage: this.startPage, pageSize: this.pageSize }).then(res => {
        if (res.data.totalPage) {
          this.total = res.data.totalPage * this.pageSize
          this.totalPage = res.data.totalPage
          this.tablePageData = res.data.list
          this.tablePageData.forEach(item => {
            item.isAbleS = item.isAble === 0
            if (!item.schoolName) item.schoolName = '-'
          })
        } else {
          this.total = 0
          this.tablePageData = []
        }
      })
    },
    search () {
      const searchValue = this.searchValue
      if (searchValue === '') {
        this.$message.error('请输入搜索内容')
        return false
      }
      SysService.search({ name: searchValue }).then(res => {
        this.searchStatue = true
        this.total = res.data.length || 0
        if (this.total) {
          this.tableData = res.data
        } else {
          this.tableData = []
        }
        this.dataPages()
      })
    },
    dataPages () {
      const tableData = this.tableData
      const startPage = (this.startPage - 1) * this.pageSize
      if (this.total) {
        this.tablePageData = tableData.slice(startPage, startPage + this.pageSize)
        this.tablePageData.forEach(item => {
          item.isAbleS = item.isAble === 0
        })
      } else {
        this.total = 0
        this.tablePageData = []
      }
    },
    isInfo () {
      const accountAddInfo = sessionStorage.getItem('accountAddInfo')
      if (accountAddInfo) {
        const info = JSON.parse(sessionStorage.getItem('accountAddInfo'))
        sessionStorage.removeItem('accountAddInfo')
        const h = this.$createElement
        this.$alert(
          h('ul', { style: 'line-height: 24px;margin-bottom: 6px;' }, [
            h('li', null, [h('label', null, '账号：'), h('span', { style: 'width: 92px;display: inline-block;text-align: left;' }, info.data.phone)]),
            h('li', null, [h('label', null, '密码：'), h('span', { style: 'width: 92px;display: inline-block;text-align: left;' }, info.data.password)]),
            h('li', { style: 'margin-top: 12px;' }, [
              h('div', { class: 'tipss' }, 'tips：请将账号密码复制到其它区域保存，该系统为了保证账号安全，只可联系平台管理员修改，平台管理员微信：'),
              h('span', { class: 'forestgreen' }, 'jintian5453')
            ])

          ]),
          info.msg, {
            type: 'success',
            roundButton: true,
            confirmButtonText: '复制账号密码',
            center: true,
            dangerouslyUseHTMLString: true
          }
        ).then(() => {
          var input = document.createElement('input') // 直接构建input
          input.value = '账号：' + info.data.phone + ' 密码：' + info.data.password // 设置内容
          document.body.appendChild(input) // 添加临时实例
          input.select() // 选择实例内容
          document.execCommand('Copy') // 执行复制
          document.body.removeChild(input) // 删除临时实例
          this.$message({
            type: 'success',
            message: '复制成功，请尽快保存!'
          })
        })
      }
    },
    reset () {
      this.searchStatue = false
      this.searchValue = ''
      this.startPage = 1
      this.getListData()
    },
    formatterRoleType (row, column, cellValue) {
      if (cellValue === '9') {
        return '机构管理员'
      } else if (cellValue === '10') {
        return '学校管理员'
      } else if (cellValue === '11') {
        return '筛查员'
      }
    },
    delSysUser (type, id) {
      let list = []
      if (type === 1) {
        this.$confirm('请确认要全部删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const multipleSelection = this.multipleSelection
          multipleSelection.forEach(item => {
            list.push(item.id)
          })
          SysService.del({ list: list }).then(res => {
            this.$message.success(res.errMsg)
            this.startPage = 1
            this.getListData()
          })
        }).catch(() => {
          this.$message({ type: 'info', message: '已取消删除' })
          return false
        })
      } else {
        this.$confirm('此操作将永久删除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          list = [id]
          SysService.del({ list: list }).then(res => {
            this.$message.success(res.errMsg)
            this.startPage = 1
            this.getListData()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }
    },
    handleCurrentChange (val) {
      this.startPage = val
      if (this.searchStatue) {
        this.dataPages()
      } else {
        this.getListData()
      }
    },
    formNull (row, column, cellValue) {
      if (cellValue === '') {
        return '-'
      } else {
        return cellValue
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'single-row'
      } else {
        return 'double-row'
      }
    },
    handleSelectionChange (rows) {
      this.multipleSelection = rows
      this.batchNum = rows.length
    }
  }

}
</script>
<style lang="scss" scoped>
.page{
  padding: 10px 20px;
  min-height: 100%;
  background-color: #fff;
}
  .page .header{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0 16px;
  }
  .page .header .el-input,.page .header .el-button{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .header-search .search>>>.el-input__inner{
    width: 178px;
    border: 1px solid #4DAF5A;
    border-radius: 18px;
    margin-right: 16px;
  }
  .header-button{
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 30px;
  }
.delete-button{
  margin-top: 20px;
}
.table-page{
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}
</style>
